import { useState,useEffect,useRef } from "react";
import { requestTTS, ChatAppResponse } from "../../api";
import { IconButton } from "@fluentui/react";
import showdown from 'showdown';
import DOMPurify from 'dompurify';

interface Props {
    url: string | null;
    darkmode: boolean;
    playbackrate: string | null;
    currenttime: number;
    setCurrentTime: (currenttime: number) => void;
    isMsgPlaying: boolean;
    setIsMsgPlaying: (isMsgPlaying: boolean) => void;
    thisMsgIsPlaying: boolean;
    setThisMsgIsPlaying: (thisMsgIsPlaying: boolean) => void;
    stopMsgPlaying: boolean;
    text: string;
    isCancelled: boolean;
    setIsCancelled: (cancelled: boolean) => void;
    isAudio: boolean;
    setIsAudio: (isAudio: boolean) => void;
    isPlaying: boolean;
    setIsPlaying: (isAudio: boolean) => void;
    isPaused: boolean;
    setIsPaused: (isAudio: boolean) => void;
    translatedText: string;
    setTranslatedtext: (isAudio: string) => void;
    isMsgRenderComplete: boolean;
    speechText: string;
    setSpeechText: (speechText: string) => void;
    answers: [user: string, response: ChatAppResponse][];
    popUpBlocker: boolean;
}

let audio = new Audio();

const preprocessText = (text: string) => {
    // Remove markdown links and extract the hypertext
    text = text.replace(/\[([^\]]+)\]\([^)]+\)/g, '$1');
    // Remove markdown bolding
    text = text.replace(/\*\*(.*?)\*\*/gs, '$1');
    // Remove extra HTML
    text = text.replace(/<!-- -->/g, '');

    // Sanitize the translated text before converting to Markdown
    text = DOMPurify.sanitize(text, {
        ALLOWED_TAGS: ['b', 'i', 'em', 'strong', 'a'],
        ALLOWED_ATTR: ['href']
    });

    return text;
};

export const SpeechOutput = ({ url, darkmode, playbackrate, currenttime, setCurrentTime, 
                            isMsgPlaying, setIsMsgPlaying, thisMsgIsPlaying, 
                            setThisMsgIsPlaying, stopMsgPlaying, text, isCancelled,
                            setIsCancelled, isAudio, setIsAudio, isPlaying, setIsPlaying,
                            isPaused, setIsPaused, translatedText, setTranslatedtext, isMsgRenderComplete,
                            speechText, setSpeechText, answers, popUpBlocker
                            }: Props) => {
    
    //const utteranceRef = useRef<SpeechSynthesisUtterance>(new SpeechSynthesisUtterance(''));
    const [language, setLanguage] = useState<string>(document.documentElement.lang);
    //const [voices, setVoices] = useState<SpeechSynthesisVoice[]>([]);
    const converter = new showdown.Converter();
    /*
    // Holds the current text to be spoken or being spoken
    let stext = useRef<string>('');
    // Holds the remaining text to be spoken
    let rtext = useRef<string>('');

    // Handles voice selection and updates the voice list
    useEffect(() => {
        const handleVoicesChanged = () => {
            const voicesList = window.speechSynthesis.getVoices();
            setVoices(voicesList);
            voicesList.forEach(voice => {
                //console.log(`Voice: ${voice.name}, Lang: ${voice.lang}, Default: ${voice.default}`);
            });
        };

        // Add event listener for voiceschanged
        window.speechSynthesis.addEventListener('voiceschanged', handleVoicesChanged);

        // Load voices initially
        handleVoicesChanged();

        // Cleanup event listener on component unmount
        return () => {
            window.speechSynthesis.removeEventListener('voiceschanged', handleVoicesChanged);
        };
    }, []);
    */
    // Function to pause audio
    const pauseAudio = () => {
        //console.log("pausing audio");

        // Set the speech text to the remaining text
        //stext.current = rtext.current;
        //window.speechSynthesis.cancel();
        setIsPlaying(false);
        setIsMsgPlaying(false);
        setThisMsgIsPlaying(false);
        setIsPaused(true);
        setIsAudio(true);

        audio.pause();
        setCurrentTime(audio.currentTime);
    };
    /*
    // Function to resume audio
    const resumeAudio = () => {
        //console.log("resuming audio");

        // Resume and read the remaining text
        //utteranceRef.current.text = stext.current;
        //window.speechSynthesis.speak(utteranceRef.current);

        setIsPaused(false);
        setIsPlaying(true);
        setIsMsgPlaying(true);
        setThisMsgIsPlaying(true);
        setIsAudio(true);
    };
    */

    // Play or pause the audio, runs when button is pressed.
    const startOrStopAudio = async () => {

        // Controls pausing and resuming of audio
        if (isPlaying) {
            pauseAudio();
            return;
        }
        
        // If any answer has audio, reset all answers speech
        if(isAudio){
            setIsCancelled(true);
            setIsAudio(false);
        }

        // Reset states for new speech
        //window.speechSynthesis.cancel();
        /*
        setIsMsgPlaying(false);
        setThisMsgIsPlaying(false);
        setIsPaused(false);
        setIsPlaying(true);
        */

        //console.log("starting new audio");
        //const processedText = preprocessText(converter.makeMarkdown(translatedText))
        /*
        if (stext.current === '')
            stext.current = processedText;
        utteranceRef.current.text = stext.current;

        if (playbackrate) {
            utteranceRef.current.rate = parseFloat(playbackrate);
        }
        
        // Sets voice to default Ava voice, should be controlled by config
        if (selectedVoice){
            utteranceRef.current.voice = selectedVoice
            console.log('voice + ' + utteranceRef.current.voice.name);
            window.speechSynthesis.speak(utteranceRef.current);
        
        // Return to old implementation if voice not found
        }else{
        */
        //console.log('voice not found, using Audio');

        if (!url) {
            console.error("Speech output is not yet available.");
            return;
        }
        if (!isPaused) {
            //console.log("state is not paused");
            audio = new Audio(url);
        } else {
            //console.log('resuming audio');
            audio = new Audio(url);
            audio.currentTime = currenttime;
            //console.log("setting pause state to false");
            setIsPaused(false);
        }

        if (playbackrate) {
            audio.playbackRate = parseFloat(playbackrate);
        }

        await audio.play();
        //}
        
        /*
        // Runs every time a word is spoken
        // Updates the remaining text to be spoken
        utteranceRef.current.onboundary = (event) => {
            if (event.name === 'word') {

                // Updates the remaining text to be spoken
                const charIndex = event.charIndex;
                rtext.current = (stext.current.slice(charIndex));

                //console.log('stext ' + stext.current + ' rtext ' + rtext.current);

            }
        };

        // Runs when the speech is finished
        utteranceRef.current.onend = () => {
            //console.log('over')
            window.speechSynthesis.cancel();
            setIsPlaying(false);
            //setIsPaused(false);
            setIsMsgPlaying(false);
            setThisMsgIsPlaying(false);
            setIsCancelled(true);
            setIsAudio(false);
            stext.current = processedText
            rtext.current = processedText
        };
        */
        audio.addEventListener("ended", () => {
            setIsPlaying(false);
            setIsMsgPlaying(false);
            setThisMsgIsPlaying(false);
            //console.log("done playing audio");
        });
        
        // Set states for speech on start
        setIsMsgPlaying(true);
        setThisMsgIsPlaying(true);
        setIsPaused(false);
        setIsPlaying(true);
        setIsAudio(true);
    
    };

    // Resets speech when another answer begins to play
    useEffect(() => {
        if (isCancelled && !thisMsgIsPlaying) {

            //console.log('cancelling ' + stext.current);
            setIsCancelled(false);
            //stext.current = rtext.current;

        }
    }, [isCancelled]);

    // Updates language and text when the translated text changes
    useEffect(() => {

        setLanguage(document.documentElement.lang);
        //utteranceRef.current.lang = document.documentElement.lang;
        //stext.current = preprocessText(converter.makeMarkdown(translatedText));
        
    }, [document.documentElement.lang, translatedText]);

    // Pauses speech when a new answer is added
    useEffect(() => {
        if (thisMsgIsPlaying) {
            //console.log('forcepausing ' + stext.current);
            pauseAudio();
        }
    }, [answers.length]);

    useEffect(() => {
        if (currenttime==0 && isPaused) {
            setCurrentTime(0);
            //console.log("*current time is 0 and paused, setting isPaused to false");
            setIsPaused(false);
            setThisMsgIsPlaying(false);
            setIsPlaying(false);
            //setIsMsgPlaying(false);
        }
      

    }, [currenttime]);

    useEffect(() => {
        if (stopMsgPlaying && thisMsgIsPlaying) {
            //console.log("stopping audio");
            pauseAudio();
        }
    }, [stopMsgPlaying]);

    // Controls display of pause button
    const color = isPlaying ? "#329887" : darkmode ? "white" : "black";
    const isdisabled = () => {
         let x = true;
        //console.log("isMsgPlaying: " + isMsgPlaying + " thisMsgIsPlaying: " + thisMsgIsPlaying + " URL: " + url);
         if (!url) {
                x = true;
         } else{
            //console.log("isMsgPlaying: " + isMsgPlaying + " thisMsgIsPlaying: " + thisMsgIsPlaying);
            x = isMsgPlaying && !thisMsgIsPlaying;
         }
      
         return x;
    };

    return (
        // <div>
        <IconButton
            style={{ color: color, backgroundColor: "transparent",  cursor: !popUpBlocker ? "pointer" : "not-allowed" }}
            iconProps={{ iconName: isPaused ? "CirclePause" : "Volume3" }}
            title="Speak answer"
            ariaLabel="Speak answer"
            onClick={() => !popUpBlocker && startOrStopAudio()}
            disabled={isdisabled()}
        />
       
        // </div>
    );
};