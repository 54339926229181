import { SetStateAction, useState, useContext } from "react";
import { AppStateContext } from "../../state/AppProvider";
import styles from "./QuestionInput.module.css";
import Microphone from "../../assets/greenMic.svg";
import { AudioConfig, SpeechRecognizer, SpeechConfig, AutoDetectSourceLanguageConfig, ResultReason } from 'microsoft-cognitiveservices-speech-sdk';
import { getSpeechAuthToken } from "../../api";
interface Props {
    updateQuestion: (question: string) => void;
    microphoneActive: boolean;
    setmicrophoneActive: (microphoneActive: boolean) => void;
    question: string;
    popUpBlocker: boolean;
    setPopUpBlocker: (popUpBlocker: boolean) => void;
}

const SpeechRecognition = (window as any).speechRecognition || (window as any).webkitSpeechRecognition;
let speechRecognition: {
    continuous: boolean;
    lang: string;
    interimResults: boolean;
    maxAlternatives: number;
    start: () => void;
    onresult: (event: { results: { transcript: SetStateAction<string> }[][] }) => void;
    onend: () => void;
    onerror: (event: { error: string }) => void;
    stop: () => void;
} | null = null;
 
try {
    speechRecognition = new SpeechRecognition();
    if (speechRecognition != null) {
        speechRecognition.lang = "en-US";
        speechRecognition.interimResults = true;
        speechRecognition.continuous = false;
    }
} catch (err) {
    console.error("Speech Recognition not supported");
    speechRecognition = null;
}

export const SpeechInput = ({ updateQuestion, microphoneActive, setmicrophoneActive,question, popUpBlocker, setPopUpBlocker}: Props) => {
    const [isRecording, setIsRecording] = useState<boolean>(false);
    const appStateContext = useContext(AppStateContext)
    const branding = appStateContext?.state.frontendSettings?.branding;
    const speechWaitingMsg = branding?.speech_waiting_msg ? branding?.speech_waiting_msg : "I'm listening...";
    const speechErrorMsg = branding?.speech_error_msg ? branding?.speech_error_msg : "I'm sorry I am having difficulties understanding. Please ensure your microphone is working properly or select a language above.";
    const defaultLanguageList = branding?.speech_default_language_list ? branding?.speech_default_language_list : 'en-US';
    const nonDefaultLanguageList = branding?.speech_non_default_language_list ? branding?.speech_non_default_language_list : '';
    const confidenceScoring = branding?.speech_enable_confidence_score ? branding?.speech_enable_confidence_score : 'N';
    
    const setSpeechRecognitionLanguage = () => {
        var fdl: Array<string> = [];
        var dLL = defaultLanguageList.split(',');
        dLL.forEach((element) => {
            fdl.push(element);
        });
        //other languages that are not the default - match to google translate list
        var ndl: Array<string> = [];
        var nLL = nonDefaultLanguageList.split(',');
        nLL.forEach((element) => {
            ndl.push(element);
        });
        var nd: Array<string> = [];
        ndl.forEach((element) => {
            nd.push(element.split('-')[0]);
        });

        var ad: Array<string> = [];
        fdl.forEach((element) => {
            ad.push(element.split('-')[0]);
        });
        var l = document.documentElement.lang;
        //console.log("this is the page language " + l)
        if (ad.indexOf(l) == -1) {
            //not in default list
            var l1 = l;
            //check non default list
            if (l.indexOf('-') == -1) {

                var r = nd.indexOf(l);
                //not accepted language 
                if (r == -1) {
                    l1 = "en-US";
                } else {
                    l1 = ndl[r];
                }
                // switch(l) { 
                //     case 'ar': { 
                //        l1= "ar-AE";
                //        break; 
                //     } 
                //     case 'ru': { 
                //        l1= "ru-RU"
                //        break; 
                //     } 
                //     case 'pl':{
                //         l1= "pl-PL"
                //         break; 
                //     }
                //     case 'ko':{
                //         l1="ko-KR"
                //         break; 
                //     }
                //     default: { 
                //        l1=l;
                //        break; 
                //     } 
                //  } 
            }
            //removes the last item from list
            //fdl.pop()
            //clear out the list
            fdl = [];
            fdl.push(l1)
        }
        //console.log("NEW FULL LIST: ")
        //console.log(fdl)
        return fdl[0]; 
      
    }
    const speechToTextFromMicrophoneInput = async () => {

       const auth = await getSpeechAuthToken();

        if (!auth) {
            console.error('Speech was cancelled. Auth token cannot be retrieved.');
            return;
        }
        const speechConfig = SpeechConfig.fromSubscription(auth.access_token, auth.region);
 
        const deflang = setSpeechRecognitionLanguage();
        speechConfig.speechRecognitionLanguage = deflang

        const autoDetectSourceLanguageConfig = AutoDetectSourceLanguageConfig.fromLanguages([deflang]);

        const audioConfig = AudioConfig.fromDefaultMicrophoneInput();
        const recognizer = SpeechRecognizer.FromConfig(speechConfig, autoDetectSourceLanguageConfig, audioConfig);
        setTimeout(() => setmicrophoneActive(true), 1000);
        setTimeout(() => updateQuestion(speechWaitingMsg), 1000);
        setIsRecording(true);
        recognizer.recognizeOnceAsync(result => {

            if (result.reason === ResultReason.RecognizedSpeech) {

                //console.log("comes here " + result.text + "***")
                if (confidenceScoring === 'Y') {

                    //console.log("Comes here " + result.languageDetectionConfidence + " *** " + result.text + " *** " + result.language)
                    if (result.languageDetectionConfidence === "High") {
                        updateQuestion(result.text);
                    }
                    else {
                        updateQuestion(speechErrorMsg);

                    }
                }
                else {
                    updateQuestion(result.text);
                }

            } else {
                updateQuestion(speechErrorMsg);

            }
            setIsRecording(false);
            setmicrophoneActive(false);
        });
    }
    const startRecording = () => {
         
       
        if (speechRecognition == null) {
            updateQuestion(speechErrorMsg);
            //updateQuestion(" no speech recognition available");
            return;
        }
        speechRecognition.lang = setSpeechRecognitionLanguage();
        updateQuestion(speechWaitingMsg);
        speechRecognition.onresult = (event: { results: { transcript: SetStateAction<string> }[][] }) => {
            let input = "";
             
            for (const result of event.results) {
               
                input += result[0].transcript;
                 
                
            }
            //console.log(input)
            updateQuestion(input);

           
        };
      
        speechRecognition.onend = () => {
            // NOTE: In some browsers (e.g. Chrome), the recording will stop automatically after a few seconds of silence.
            setIsRecording(false);
            setmicrophoneActive(false);
            setPopUpBlocker(false);
        };
        speechRecognition.onerror = (event: { error: string }) => {
            if (speechRecognition) {
                speechRecognition.stop();
                if (event.error == "no-speech") {
                    updateQuestion(speechErrorMsg);
                    //updateQuestion(" no speech ");
                } else if (event.error == "language-not-supported") {
                    console.log(
                        `Speech recognition error detected: ${event.error}. The speech recognition input functionality does not yet work on all browsers, like Edge in Mac OS X with ARM chips. Try another browser/OS.`
                    );
                    updateQuestion(speechErrorMsg);
                    //updateQuestion(" language not supported");
                } else {
                    console.log(`Speech recognition error detected: ${event.error}.`);
                    updateQuestion(speechErrorMsg);
                    //updateQuestion(event.error);
                }
            }
        };

        setIsRecording(true);
        setmicrophoneActive(true);
        speechRecognition.start();
        setPopUpBlocker(true);
    };

    const stopRecording = () => {
        if (speechRecognition == null) {
            //updateQuestion(" no speech recognition available");
            updateQuestion(speechErrorMsg);
            return;
        }
        //console.log("stop recording" + question)
        if (question === speechWaitingMsg) {
            updateQuestion(speechErrorMsg);
           
        }
        speechRecognition.stop();
        setIsRecording(false);
        setmicrophoneActive(false);
        setPopUpBlocker(false);
    };

    // if (speechRecognition == null) {
    //     return <></>;
    // }
    return (
        <>
            {speechRecognition != null &&  !isRecording && (
                <div className={styles.questionSpeechButtonContainer}   onClick={() => !popUpBlocker && startRecording()} style={{ cursor: popUpBlocker ? 'not-allowed' : 'pointer' }}>
                    <img src={Microphone} className={styles.questionSpeechButton} title="Start speaking" />
                  
                </div>
            )}
            {speechRecognition != null && isRecording && (
                 <div className={styles.questionSpeechButtonContainerAnimation}   onClick={() => stopRecording()}>
                    <img src={Microphone} className={styles.questionSpeechButton} title="Stop speaking"   />
                   
                </div>
            )}
            {speechRecognition == null && (
                <div className={microphoneActive ? styles.questionSpeechButtonContainerAnimation : styles.questionSpeechButtonContainer} 
                    role="button"
                    tabIndex={0}
                    title="Use microphone"
                    aria-label="Use microphone"
                    onClick={() => speechToTextFromMicrophoneInput()}
                    onKeyDown={e => e.key === "Enter" || e.key === " " ? speechToTextFromMicrophoneInput() : null}
                >
                    <img src={Microphone} className={styles.questionSpeechButton} title="Use microphone" />
                </div>
            )
            }
        </>
    );
};
 