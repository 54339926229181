import React, { createContext, useReducer, ReactNode, useEffect } from 'react';
import { appStateReducer } from './AppReducer';
import { ChatHistoryLoadingState, CosmosDBHealth, historyList, historyEnsure, CosmosDBStatus } from '../api';
import { Conversation, frontendSettings, FrontendSettings  } from '../api';
  
export interface AppState {
    // showHideCitations: HideCitations;
    // showHideMsgFb: ShowMsgFb;
    isChatHistoryOpen: boolean;
    chatHistoryLoadingState: ChatHistoryLoadingState;
    isCosmosDBAvailable: CosmosDBHealth;
    chatHistory: Conversation[] | null;
    filteredChatHistory: Conversation[] | null;
    currentChat: Conversation | null;
    isFeedbackPanelOpen: boolean;
    feedbackPanelPayload: string;
    frontendSettings: FrontendSettings | null;
    isMsgFB: string;
}

export type Action =
    // | { type: 'HIDE_CITATIONS', payload: HideCitations }
    // | { type: 'HIDE_MSG_FB', payload: ShowMsgFb }
    | { type: 'TOGGLE_CHAT_HISTORY' }
    | { type: 'SET_COSMOSDB_STATUS', payload: CosmosDBHealth }
    | { type: 'UPDATE_CHAT_HISTORY_LOADING_STATE', payload: ChatHistoryLoadingState }
    | { type: 'UPDATE_CURRENT_CHAT', payload: Conversation | null }
    | { type: 'UPDATE_FILTERED_CHAT_HISTORY', payload: Conversation[] | null }
    | { type: 'UPDATE_CHAT_HISTORY', payload: Conversation } // API Call
    | { type: 'UPDATE_CHAT_TITLE', payload: Conversation } // API Call
    | { type: 'DELETE_CHAT_ENTRY', payload: string } // API Call
    | { type: 'DELETE_CHAT_HISTORY'}  // API Call
    | { type: 'DELETE_CURRENT_CHAT_MESSAGES', payload: string }  // API Call
    | { type: 'FETCH_CHAT_HISTORY', payload: Conversation[] | null }  // API Call
    | { type: 'TOGGLE_FEEDBACK_PANEL' }
    | { type: 'CLOSE_FEEDBACK_PANEL' }
    | { type: 'FETCH_FRONTEND_SETTINGS', payload: FrontendSettings | null }  // API Call
    | { type: 'MSGFB', payload: string }
    | { type: 'ENV_UPDATE_FRONTEND_SETTINGS', payload: FrontendSettings | null }  // API Call

const initialState: AppState = {
    // showHideCitations:  {
    //     hideCitations: "false"
    // },
    // showHideMsgFb:  {
    //     showMsgFb: "false"
    // },
    isChatHistoryOpen: false,
    chatHistoryLoadingState: ChatHistoryLoadingState.Loading,
    chatHistory: null,
    filteredChatHistory: null,
    currentChat: null,
    isCosmosDBAvailable: {
        cosmosDB: false,
        status: CosmosDBStatus.NotConfigured,
        allowMessageDelete: "false"
    },
    isFeedbackPanelOpen: false,
    feedbackPanelPayload: '',
    frontendSettings: null,
    isMsgFB:"N"
};

export const AppStateContext = createContext<{
    state: AppState;
    dispatch: React.Dispatch<Action>;
  } | undefined>(undefined);

type AppStateProviderProps = {
    children: ReactNode;
  };
  
  export const AppStateProvider: React.FC<AppStateProviderProps> = ({ children }) => {
    const [state, dispatch] = useReducer(appStateReducer, initialState);

    useEffect(() => {
        // Check for cosmosdb config and fetch initial data here
        // const getshowhideMsgFb = async () => {
        //     gethideMsgFb().then((response) => {
               
        //         if(response?.showMsgFb){
        //             dispatch({ type: 'HIDE_MSG_FB', payload: response });
        //         }
        //         else {
        //             dispatch({ type: 'HIDE_MSG_FB', payload: { showMsgFb: "false"} });
        //         }
            
        //     })
        //     .catch((err) => {
        //         dispatch({ type: 'HIDE_MSG_FB', payload: { showMsgFb: "false"} });
        //     })
        // };
        // const getshowhideCitations = async () => {
        //     gethideCitations().then((response) => {
               
        //         if(response?.hideCitations){
        //             dispatch({ type: 'HIDE_CITATIONS', payload: response });
        //         }
        //         else {
        //             dispatch({ type: 'HIDE_CITATIONS', payload: { hideCitations: "false"} });
        //         }
            
        //     })
        //     .catch((err) => {
        //         dispatch({ type: 'HIDE_CITATIONS', payload: { hideCitations: "false"} });
        //     })
        // };

        const fetchChatHistory = async (offset=0): Promise<Conversation[] | null> => {
            const result = await historyList(offset).then((response) => {
                if(response){
                    dispatch({ type: 'FETCH_CHAT_HISTORY', payload: response });
                }else{
                    dispatch({ type: 'FETCH_CHAT_HISTORY', payload: null });
                }
                return response
            })
            .catch((err) => {
                dispatch({ type: 'UPDATE_CHAT_HISTORY_LOADING_STATE', payload: ChatHistoryLoadingState.Fail });
                dispatch({ type: 'FETCH_CHAT_HISTORY', payload: null });
                console.error("There was an issue fetching your data.");
                return null
            })
            return result
        };

        const getHistoryEnsure = async () => {
            dispatch({ type: 'UPDATE_CHAT_HISTORY_LOADING_STATE', payload: ChatHistoryLoadingState.Loading });
            historyEnsure().then((response) => {
                if(response?.cosmosDB){
                    fetchChatHistory()
                    .then((res) => {
                        if(res){
                            dispatch({ type: 'UPDATE_CHAT_HISTORY_LOADING_STATE', payload: ChatHistoryLoadingState.Success });
                            dispatch({ type: 'SET_COSMOSDB_STATUS', payload: response });
                        }else{
                            dispatch({ type: 'UPDATE_CHAT_HISTORY_LOADING_STATE', payload: ChatHistoryLoadingState.Fail });
                            dispatch({ type: 'SET_COSMOSDB_STATUS', payload: {cosmosDB: false, status: CosmosDBStatus.NotWorking, allowMessageDelete: "false"} });
                        }
                    })
                    .catch((err) => {
                        dispatch({ type: 'UPDATE_CHAT_HISTORY_LOADING_STATE', payload: ChatHistoryLoadingState.Fail });
                        dispatch({ type: 'SET_COSMOSDB_STATUS', payload: {cosmosDB: false, status: CosmosDBStatus.NotWorking, allowMessageDelete: "false"} });
                    })
                }else{
                    dispatch({ type: 'UPDATE_CHAT_HISTORY_LOADING_STATE', payload: ChatHistoryLoadingState.Fail });
                    dispatch({ type: 'SET_COSMOSDB_STATUS', payload: response });
                }
            })
            .catch((err) => {
                dispatch({ type: 'UPDATE_CHAT_HISTORY_LOADING_STATE', payload: ChatHistoryLoadingState.Fail });
                dispatch({ type: 'SET_COSMOSDB_STATUS', payload: {cosmosDB: false, status: CosmosDBStatus.NotConfigured, allowMessageDelete: "false"} });
            })
        }
        getHistoryEnsure();
        // getshowhideCitations();
        // getshowhideMsgFb();
    }, []);
    useEffect(() => {
         
        const getFrontendSettings = async () => {
            frontendSettings().then((response) => {
                dispatch({ type: 'FETCH_FRONTEND_SETTINGS', payload: response as FrontendSettings });
            })
            .catch((err) => {
                console.error("There was an issue fetching your data.");
            })
        }
        getFrontendSettings();
    }, []);
    return (
      <AppStateContext.Provider value={{ state, dispatch }}>
        {children}
      </AppStateContext.Provider>
    );
  };

