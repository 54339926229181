import React, { useEffect, useState } from 'react';

interface Props {
    ddInput: string[];
    showDropDown: boolean;
    toggleDropDown: Function;
    ddSelection: Function;
    popUpBlocker: boolean;
    setPopUpBlocker: (popUpBlocker: boolean) => void;
};

export const DropDown = ({ ddInput,ddSelection, popUpBlocker,setPopUpBlocker }: Props) => {

    const [showDropDown, setShowDropDown] = useState<boolean>(false);

     
    const onClickHandler = (ddName: string, index:number): void => {
        //console.log(ddName);
        setPopUpBlocker(false);
        ddSelection(ddName,index);
    };

    useEffect(() => {
        setShowDropDown(showDropDown);
    }, [showDropDown]);

    return (
        <>
            {/* <div className={showDropDown ? 'dropdown' : 'dropdown active'}> */}
            <div>
                {ddInput.map(
                    (ddName: string, index: number): JSX.Element => {
                        return (
                           
                            <p 
                                key={ddName}
                                onClick={(): void => {
                                    onClickHandler(ddName,index);
                                }}
                                style={{ cursor: 'pointer' }}
                            >
                                {ddName.split('<>')[0]}
                            </p>
                        );
                    }
                )}
            </div>
        </>
    );
}; 